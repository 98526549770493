import React from 'react';

import { PageLayout } from 'layouts/PageLayout';
import { Map } from 'sawada.components/Map';
import * as styles from "./styles.module.scss"

const MapContainer= () => (
  <div className={ styles.MapContainer }>
    <Map/>
  </div>
)

const InfoContainer= () => (
  <>
    <p>〒064-0822 札幌市中央区北２条西２５丁目１−６</p>
    <p>地下鉄東西線線　<strong>円山公園</strong>駅より徒歩5分</p>
  </>
)

export const Access = (props) => {
  return (
    <PageLayout>
      <MapContainer/>
      <PageLayout.Title>Access</PageLayout.Title>
      <InfoContainer/>
    </PageLayout>
  )
}