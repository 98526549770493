import React from "react"

import * as styles from "./styles.module.scss"

import { TimeTable as BaseTimeTable } from "sawada.components/TimeTable"

import ImgLogo from "images/logo.png"

const Logo = () => (
  <div className={styles.TopLogo}>
    <img className={styles.TopLogo_Img} src={ImgLogo} alt="さわだ整骨院" />
  </div>
)

const TimeTable = () => (
  <>
    <BaseTimeTable />
  </>
)

const HeadText = () => (
  <>
    <p>
      <strong>札幌</strong>
      <strong>円山公園</strong>駅徒歩５分の<strong>整骨院</strong>
    </p>
    <p>症状に合うオーダーメイド施術をご提案</p>
  </>
)

export const Main = props => {
  return (
    <div className={styles.Container}>
      <HeadText />
      <Logo />
      <TimeTable />
    </div>
  )
}
