import React from 'react';

import { PageLayout } from 'layouts/PageLayout';
import * as styles from "./styles.module.scss"

import ImgGreen from 'images/bg_green.png';


const SubTitle = ({ children }) => (
  <h3><span className={ styles.SubTitle }>{ children }</span></h3>
)

const PriceList = ({ children }) => (
  <ul className={ styles.PriceList }>
    { children }
  </ul>
)
const PriceListItem = ({ children }) => (
  <li className={ styles.PriceList_Item } >{ children }</li>
)
const PriceListNote = ({ children }) => (
  <p className={ styles.PriceList_Note } >{ children }</p>
)

const BasicPriceList= () => (
  <PriceList>
    <SubTitle>基本施術</SubTitle>
    <PriceListItem><strong>電気治療 ＋ マッサージ</strong>1,500円</PriceListItem>
    <PriceListNote >※保険適用の場合は200〜1,500円</PriceListNote >
  </PriceList>
)

const PelvisPriceList= () => (
  <PriceList>
    <SubTitle>骨盤調整</SubTitle>
    <PriceListItem><strong>骨盤調整</strong>3,500円 (初回お試し2,000円)</PriceListItem>
    <PriceListItem><strong>回数券(5回)</strong>12,500円 (１回2,500円)</PriceListItem>
    <PriceListNote >※基本施術料金が別途かかります</PriceListNote >
  </PriceList>
)

const OtherContents= () => (
  <div className={ styles.OtherContents } >
    <SubTitle>その他</SubTitle>
    <div className={ styles.OtherContents_Item} >
      <p>各種保険取扱い</p>
      <p>自由診療は、初回に初検料(500円)が別途かかります</p>
    </div>
  </div>
)

const BackgroundImage= () => (
  <img className={ styles.ImageGreenStyle } src={ ImgGreen }/>
)

export const Menu = (props) => {
  return (
    <PageLayout>
      <PageLayout.Title>Menu</PageLayout.Title>
      <BasicPriceList/>
      <PelvisPriceList/>
      <OtherContents/>
      <BackgroundImage/>
    </PageLayout>
  )
}