import React from "react"
import BackgroundSlider from 'react-background-slider'

const images = [
  "/images/top_image1.jpg",
  "/images/top_image2.jpg",
  "/images/top_image3.jpg",
  "/images/top_image4.jpg",
];
 
export const ImageGallery = () => {
    return (
      <BackgroundSlider
        images={images}
        duration={8} transition={1}
      />
    )
}